<template>
  <v-navigation-drawer left v-model="customizer.Sidebar_drawer" elevation="0" rail-width="70" mobile-breakpoint="sm" app
    class="leftSidebar" :rail="customizer.mini_sidebar">


    <!-- Logo part, hidden in mini sidebar but space remains -->
    <div class="logo-container" :class="{ 'collapsed': customizer.mini_sidebar }">
      <NuxtLink to="/dashboard/overall">
        <img src="/Sustain-SBI.svg" alt="SBI Logo" class="logo" />
        <!-- <img src="/Data Centre.svg" alt="Data Centre" class="logo-Data-Centre" /> -->
      </NuxtLink>
    </div>

    <!-- Navigation items -->
      <v-list aria-busy="true" class="px-2" aria-label="menu list">
        <template v-for="(item, i) in sidebarMenu" :key="i">
          <!-- <NavGroup v-if="item.header" :item="item" :key="item.title" /> -->
          <v-divider v-if="item.divider" />
          <!-- <NavCollapse v-else-if="item.children" :item="item" :level="0" class="leftPadding" /> -->
          <SidebarNavItem v-else :item="item" />
        </template>
      </v-list>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { shallowRef } from 'vue';
import { useCustomizerStore } from '@/stores/customizer';
import sidebarItems from './sidebarItem';

const customizer = useCustomizerStore();
const sidebarMenu = shallowRef(sidebarItems);

onMounted(() => {
  if (window.innerWidth < 600) {
    customizer.Sidebar_drawer = false; 
  }
});

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

/* Sidebar content, ensuring the layout remains unchanged */
.logo-container {
  padding: 20px;
  text-align: center;
}

.logo {
  width: 180px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 10px;
}

.logo-Data-Centre {
  width: 120px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 10px;
}

.logo-container.collapsed {
  visibility: hidden;
  height: 120px;
}


.v-navigation-drawer {
  font-family: 'Nunito Sans', sans-serif;
}
</style>
